import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ContactUs from "./contactUs";
import Page from "./page";
import Blog from "./Blog";
import Login from "./Login";
import TestimonialPage from "./TestimonialPage";
import PostDetails from "./postDetails";
import Services from "./Services";
import PageE from "./PageE";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    errorElement: <PageE/>
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  // {
  //   path: "/testimonials",
  //   element: <TestimonialPage />,
  // },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/blog/:slug",
    element: <PostDetails />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <router.Routes />
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
